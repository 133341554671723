import React from 'react';
import { FaSistrix, FaBullhorn, FaCheck } from 'react-icons/fa';

function InfoCards() {
  return (
    <div className="info-cards">
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaSistrix size={42} />
        </div>
        <div className="info-cards__card--text">
          <h5>Collection of events</h5>
          <p>
            Our vision is to make it easier for you, to discover what you can
            explore in UK. We have collected thousands of events and concerts,
            with the opportunity to see when and where the event it taken place.
          </p>
        </div>
      </div>
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaBullhorn size={42} />
        </div>
        <div className="info-cards__card--text">
          <h5>Go plan</h5>
          <p>
            Do you know, what to explore if you are visiting London or Newcastle
            next week? Do you know what happens on your birthday? With
            Zooticket, you can search for events all round UK, to make it easier
            for you to make plans
          </p>
        </div>
      </div>
      <div className="info-cards__card">
        <div className="info-cards__card--icon">
          <FaCheck size={42} />
        </div>
        <div className="info-cards__card--text">
          <h5>Free to use</h5>
          <p>
            Zooticket is completely free to use! We don't own the tickets. We
            are working together with major ticket providers, and our goal is to
            make it easier for you, to explore what happens around in the UK
          </p>
        </div>
      </div>
    </div>
  );
}

export default InfoCards;
