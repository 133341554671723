import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
//import { Redirect } from '@reach/router';
import { EventsContext } from '../../provider/EventProvider';
import useSearch from '../../hooks/useSearch';
import SEO from '../seo';
import Layout from '../Layout';
import EventContainer from '../EventContainer';
import Frontpage from '../Frontpage';

function CityLayout({ city }) {
  const { state } = useContext(EventsContext);
  const [isLoaded, setLoaded] = useState(false);
  //TODO: Clean up
  const [handleClick] = useSearch(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    city.toLowerCase()
  );

  useLayoutEffect(() => {
    handleClick();
  }, []);

  // if (state && state.noEvents) {
  //   return <Redirect to="/" />
  // }

  return (
    <>
      <SEO title={city} />
      <Layout withoutSVG>{state.events && <EventContainer />}</Layout>
    </>
  );
}

export default CityLayout;
