import React, { useState, useEffect } from 'react';
import {
  FaGuitar,
  FaTheaterMasks,
  FaFootballBall,
  FaRegLaughBeam,
  FaBeer,
  FaBabyCarriage,
} from 'react-icons/fa';
import useSearch from '../../hooks/useSearch';

function CategoryCards() {
  const [category, setCategory] = useState('');
  const [handleClick] = useSearch(
    undefined,
    undefined,
    undefined,
    undefined,
    category
  );

  useEffect(() => {
    if (!category) return;

    handleClick();
  }, [category]);

  return (
    <div className="category-cards">
      <h3 className="category-cards__title">Categories</h3>
      <ul className="category-cards__list">
        <li onClick={() => setCategory('music')}>
          <FaGuitar />
          <p>Music</p>
        </li>
        <li onClick={() => setCategory('theatre')}>
          <FaTheaterMasks />
          <p>Theatre</p>
        </li>
        <li onClick={() => setCategory('sport')}>
          <FaFootballBall />
          <p>Sport</p>
        </li>
        <li onClick={() => setCategory('comedy')}>
          <FaRegLaughBeam />
          <p>Comedy</p>
        </li>
        <li onClick={() => setCategory('festival')}>
          <FaBeer />
          <p>Festival</p>
        </li>
        <li onClick={() => setCategory('family')}>
          <FaBabyCarriage />
          <p>Family</p>
        </li>
      </ul>
    </div>
  );
}

export default CategoryCards;
